import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'; 
@Component({
  selector: 'app-cta',
  templateUrl: './cta.component.html',
  styleUrls: ['./cta.component.css']
})
export class CtaComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit() {
  }
  getrequestingdemo()
  {
    this.router.navigate(['/requesting-demo'], { fragment: 'Request' });
  }
}

import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router'; 


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  public contactus: ContactUS;

  constructor(private http:HttpClient, private router:Router) {

  this.contactus = new ContactUS();

   }

  ngOnInit() {
  }


  contactUs(){

   
    this.http.post(environment.url+'customer/inquiry_list/', this.contactus, {}).subscribe(data => {
      
      if (data['error'] == true){
       alert(data['message']);
     }
     
      else{
        alert(data['message']);
        this.router.navigate(['/contact'])
      
        }
             
    }) 
  }
}


export class ContactUS{
  email:string;
  first_name:string;
  last_name:string;
  message:string; 
  phone_no:string;
}

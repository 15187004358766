import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c-qurity',
  templateUrl: './c-qurity.component.html',
  styleUrls: ['./c-qurity.component.css']
})
export class CQurityComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

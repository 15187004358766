import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router'; 

@Component({
  selector: 'app-request-demo',
  templateUrl: './request-demo.component.html',
  styleUrls: ['./request-demo.component.css']
})
export class RequestDemoComponent implements OnInit {
  public contactus: ContactUS;
  // public selectedproduct:string
  private options: string[] = ["cQan", "cQreen", "cQurity"];
  selectedproduct = "cQan";
  constructor(private http:HttpClient, private router:Router) {

  this.contactus = new ContactUS();

   }

  ngOnInit() {
    this.selectedproduct="cQan"
  }


  contactUs(){

   this.contactus.product=this.selectedproduct
    this.http.post(environment.url+'customer/request_demo/', this.contactus, {}).subscribe(data => {
      
      if (data['error'] == true){
       alert(data['message']);
     }
     
      else{
        alert(data['message']);
        this.clearFields()
        this.router.navigate(['/request-demo'])
      
        }
             
    }) 
  }

  clearFields()
{
  this.contactus.email=""
  this.contactus.first_name=""
  this.contactus.last_name=""
  this.contactus.message=""
  this.contactus.phone_no=""
  this.contactus.product=""

}

}



export class ContactUS{
  email:string;
  first_name:string;
  last_name:string;
  message:string; 
  phone_no:string;
  product :string
}
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router'; 

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.css']
})
export class BlogDetailComponent implements OnInit {

  public blog: any;
  public url = environment.imgurl

  constructor(private http:HttpClient, private router:Router) { }

  ngOnInit() {

    this.getBlogDetail()
  }


  getBlogDetail(){

   
    this.http.get(environment.url+'admin/blog_details/'+localStorage.getItem('slug')+'/',{}).subscribe(data => {
      
      if (data){
       
        this.blog = data['result'];

        console.log(this.blog.id)
     }
     
      
             
    }) 
  }

}

import { Component, OnInit } from '@angular/core';
import {HomeService} from '../services/home.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router'; 


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public login: Login;
  public url = environment.imgurl
  public signup: Signup;
  constructor(private http:HttpClient, private router:Router) { 
    this.login = new Login();
    this.signup = new Signup();
    
  }

  ngOnInit() {
    document.getElementById('signin').style.display="block"
    document.getElementById('signup').style.display="none"
  }
  cust_login(){

    
    this.http.post(environment.url+'login/', this.login, {
  
  })

    .subscribe(data => {
      
      if (data['error'] == 'True'){

       alert(data['msg']);
       
     }
     
      if (data){
       localStorage.setItem('UserName',this.login.email)
        localStorage.setItem('id_token', data['access']); //for storing token
        this.router.navigate(['/dashboard'])
      
        }
       
    }, error => {

      alert('Check your credentials.')
      this.ClearFields()
    }); 


  }
  showregister()
  {
    document.getElementById('signin').style.display="none"
    document.getElementById('signup').style.display="block"
  }
  showsignin()
  {
    document.getElementById('signin').style.display="block"
    document.getElementById('signup').style.display="none"
  }

  ClearFields() {
   
    this.login.email = "";
    this.login.password = "";
  }
  
  cust_signup(){

    this.http.post(environment.url+'customer/customers_list/', this.signup, {}).subscribe(data => {

      if (data['error'] == true){
console.log(data)
       alert(data['message']);
    
     }
      else{
        
        document.getElementById('signin').style.display="block"
        document.getElementById('signup').style.display="none"
        localStorage.setItem('id_token', data['access']); //for storing token
        this.ClearFieldsSignup()
        // this.router.navigate(['/home'])
      
        }
             
      
      // alert('SUCCESS !!');
    }) 


  }

  ClearFieldsSignup() {
    this.signup.first_name = "";
    this.signup.email = "";
    this.signup.password = "";
    this.signup.confirm_password = "";
    

  }

}


export class Login{

  email:string;
  password:string;
}

export class Signup{

  email:string;
  password:string;
  confirm_password:string;
  first_name:string; 


}
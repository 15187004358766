import { AfterContentInit, AfterViewChecked, AfterViewInit, Component, OnInit } from '@angular/core';
import { HomeService } from '../services/home.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [HomeService],

})
export class HomeComponent implements OnInit, AfterViewInit {
  particlesJS: any;

  public newssubcribe: NewsSubcribe;



  public blogList: [] = [];




  constructor(private _homeservice: HomeService, private http: HttpClient, private router: Router) {

    this.newssubcribe = new NewsSubcribe();


  }
  ngAfterViewInit(): void {
    this.loadEvents()
  }

  loadEvents() {
    $(document).ready(function () {


      if ($('.primary-header').length) {
        $('.header .primary-header .my-btn').on("click", function () {
          $(this).toggleClass('menu-open');
          $('.header .header-menu-wrap').slideToggle(300);
        });

        $('.sticky-header .primary-header .my-btn').on("click", function () {
          $(this).toggleClass('menu-open');
          $('.sticky-header .header-menu-wrap').slideToggle(300);
        });
      }
    });

  }

  ngOnInit() {
    this.getBlog()
    this.particlesJS.load('particles-js', 'particles.json', null);
    //   $(document).ready(function(){
    //     $("#testimonial-slider").owlCarousel({
    //         items:2,
    //         itemsDesktop:[1000,2],
    //         itemsDesktopSmall:[980,1],
    //         itemsTablet:[768,1],
    //         pagination:true,
    //         navigation:true,
    //         navigationText:["<",">"],
    //         autoPlay:true
    //     });
    // });
  }


  getcQuan() {
    this.router.navigate(['/cQuan'], { fragment: 'cQuan' });
  }
  getcQreen() {
    this.router.navigate(['/cQreen'], { fragment: 'cQreen' });
  }
  getcQurity() {
    this.router.navigate(['/cQurity'], { fragment: 'cQurity' });
  }

  getfinancialservice() {
    this.router.navigate(['/financial-service'], { fragment: 'Financial' });
  }
  getenterprisesecurity() {
    this.router.navigate(['/enterprise-security'], { fragment: 'enterprise' });
  }

  getbespokesoftware() {
    this.router.navigate(['/bespoke-software'], { fragment: 'Bespoke' });
  }

  getconsultingandadvisory() {
    this.router.navigate(['/consulting-and-advisory'], { fragment: 'consulting' });
  }
  getrequestingdemo() {
    this.router.navigate(['/requesting-demo'], { fragment: 'Request' });
  }





  createnewssubscribe() {


    this.http.post(environment.url + 'customer/news_subcription/', this.newssubcribe, {}).subscribe(data => {

      if (data['error'] == 'True') {

        alert(data['msg']);
        //  this.ClearFields();

      }

      if (data) {

        document.getElementById('newsletter').style.display = "none"

        alert('Successfully.....')

      }


      // alert('SUCCESS !!');
    }, error => {

      alert('News subcription with this email already exists..')
      //this.ClearFields()
    });


  }



  getBlog() {

    console.log('getblog .... is called.......')
    this.http.get(environment.url + 'admin/blog_list/', {}).subscribe(data => {

      if (data) {

        this.blogList = data['result'];
        console.log(this.blogList)
      }



    })
  }


  blogdetails(slug) {

    localStorage.setItem('slug', slug)

    this.router.navigate(['/blog-detail'], { fragment: 'blog' })


  }



}


export class NewsSubcribe {

  email: string;
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-analysis-design',
  templateUrl: './analysis-design.component.html',
  styleUrls: ['./analysis-design.component.css']
})
export class AnalysisDesignComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

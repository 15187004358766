import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutComponent } from './about/about.component';
import { AnalysisDesignComponent } from './analysis-design/analysis-design.component';
import { BespokeSoftwareComponent } from './bespoke-software/bespoke-software.component';
import { BlogComponent } from './blog/blog.component';
import { BlogDetailComponent } from './blog-detail/blog-detail.component';
import { CareerComponent } from './career/career.component';
import { ConsultingAdvisoryComponent } from './consulting-advisory/consulting-advisory.component';
import { ContactComponent } from './contact/contact.component';
import { CQreenComponent } from './c-qreen/c-qreen.component';
import { CQuanComponent } from './c-quan/c-quan.component';
import { CQurityComponent } from './c-qurity/c-qurity.component';
import { DataIntelligenceComponent } from './data-intelligence/data-intelligence.component';
import { EnterpriseSecurityComponent } from './enterprise-security/enterprise-security.component';
import { FinancialServicesComponent } from './financial-services/financial-services.component';
import { HomeComponent } from './home/home.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { TutorialComponent } from './tutorial/tutorial.component';
import { WebinarComponent } from './webinar/webinar.component';
import { FooterComponent } from './footer/footer.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { CtaComponent } from './cta/cta.component';
import { WebinarFormComponent } from './webinar-form/webinar-form.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';

import {HomeService} from './services/home.service';
import { FormsModule } from '@angular/forms';
import { RequestMethodRepository } from './common/requestmethods.repository';
import { HttpClientModule } from '@angular/common/http';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProductsComponent } from './products/products.component';
import { ServicesComponent } from './services/services.component';
import { RequestDemoComponent } from './request-demo/request-demo.component';
import { LeaveMessageComponent } from './leave-message/leave-message.component';
import { DashboardSidebarComponent } from './dashboard-sidebar/dashboard-sidebar.component';
import { DashboardTopbarComponent } from './dashboard-topbar/dashboard-topbar.component';
import { LoginComponent } from './login/login.component';
import { RequestingDemoComponent } from './requesting-demo/requesting-demo.component';
import { JobDetailComponent } from './job-detail/job-detail.component';
import {NgxPaginationModule} from 'ngx-pagination';

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: 'localhost' // or 'your.domain.com' 
  },
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#f1d600'
    }
  },
  theme: 'edgeless',
  type: 'opt-out'
};



@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    AnalysisDesignComponent,
    BespokeSoftwareComponent,
    BlogComponent,
    BlogDetailComponent,
    CareerComponent,
    ConsultingAdvisoryComponent,
    ContactComponent,
    CQreenComponent,
    CQuanComponent,
    CQurityComponent,
    DataIntelligenceComponent,
    EnterpriseSecurityComponent,
    FinancialServicesComponent,
    HomeComponent,
    TestimonialsComponent,
    TutorialComponent,
    WebinarComponent,
    FooterComponent,
    PageHeaderComponent,
    CtaComponent,
    WebinarFormComponent,
    TermsComponent,
    PrivacyPolicyComponent,
    CookiePolicyComponent,
    DashboardComponent,
    ProductsComponent,
    ServicesComponent,
    RequestDemoComponent,
    LeaveMessageComponent,
    DashboardSidebarComponent,
    DashboardTopbarComponent,
    LoginComponent,
    RequestingDemoComponent,
    JobDetailComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    NgxPaginationModule
  ],
  providers: [RequestMethodRepository, HomeService],
  bootstrap: [AppComponent]
})


export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router'; 

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.css']
})
export class CareerComponent implements OnInit {
  public selectedRow;
  public jobid :string
  public career : career
  public careermaster: career[] = [];
  public p=1
  constructor(private http:HttpClient, private router:Router) {this.career=new career(); }

  ngOnInit() {
    this.Getjoblist()
  }


  Getjoblist()
  {
    this.http.get(environment.url+'customer/jobs_list/').subscribe(data => {
      
      if (data){
        console.log(data)
        this.careermaster=data['result']
        console.log(this.careermaster)
      }
    
             
    }) 
  }

  RowSelection(career: any, i) {
    if (career && career != null) {
      this.selectedRow = i;
      this.jobid=career.jobTitle

      console.log(this.jobid)
      localStorage.setItem("jobid",this.jobid)
this.router.navigate(['/job-detail'],{ fragment: 'job' })
    }
  }



}

export class career
{
  jobTitle : string
  area :string
  position :string
  jobDescription :string
  id :string

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'; 


@Component({
  selector: 'app-dashboard-topbar',
  templateUrl: './dashboard-topbar.component.html',
  styleUrls: ['./dashboard-topbar.component.css']
})
export class DashboardTopbarComponent implements OnInit {

  UserName :any
  constructor(private router:Router) { }

  ngOnInit() {
    this.UserName=localStorage.getItem('UserName')
  }

  cust_logout()
  {
    localStorage.setItem('id_token',""); //for storing token
    localStorage.setItem('UserName',"");
    this.router.navigate(['/home'])
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// import { Observable } from 'rxjs/Observable';
import { Observable} from 'rxjs';
import { stringify } from '@angular/compiler/src/util';

 
 var t:string = localStorage.getItem('id_token');

//  console.log('Token '+t)

@Injectable(

    
)
export class RequestMethodRepository {
    constructor(protected http: HttpClient) {
    }

    Get(url: string, params?:any): Observable<any> {
        if(params){
            return this.http.get(url,  {
                params:params,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=UTF-8', 
                    'Authorization': 'Token '+t                
                   
                              
                }
            });
         
        }
        else {
            return this.http.get(url, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': 'Token '+t
                    
                   
                    
                }
            });
        }
    }

    Post(url: string, body: any): Observable<any> {
        return this.http.post(url, body, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'Token '+t
                
            }
        });
    }

    PostWithoutToken(url: string, body: any): Observable<any> {
        return this.http.post(url, body, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                
                
            }
        });
    }

    Put(url: string, body: any) {
        return this.http.put(url, body, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'Token '+t
               
            }
        });
    }

    Delete(url: string) {
        return this.http.delete(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'Token '+t
              
            }
        });
    }

    Patch(url: string, body: any) {
        return this.http.patch(url, body, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8'
                
            }
        });
    }

    GetLatLong(url: string): Observable<any> {
        return this.http.get(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8'
               
            }
        });
    }

    private GetUrlWithId(url: string, id: string): string {
        return url + "/?id=" + id;
    }


    private GetUrlWithPageOffset(url: string, page: number, offset: number): string{
        return url + "/?page=" +page + "&" + "offset=" + offset;
    }




    private GetUrlWithSearch(url: string, search: string){

        return url + "/?q=" + search;
    }

    private ExtractJSONResult(response): any {
        return response.json();
    }

    PostImage(url: string, body: any): Observable<any> {
        return this.http.post(url, body, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8'
               
            },
            responseType: 'text'
        });
    }
}
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c-quan',
  templateUrl: './c-quan.component.html',
  styleUrls: ['./c-quan.component.css']
})
export class CQuanComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router'; 

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  public blogList: [] = [];
  public url = environment.imgurl
public p=1
  constructor(private http:HttpClient, private router:Router) { }

  ngOnInit() {
    this.getBlog()
  }


  getBlog(){

   
    this.http.get(environment.url+'admin/blog_list/',{}).subscribe(data => {
      
      if (data){
       
        this.blogList = data['result'];
     }
     
      
             
    }) 
  }


  blogdetails(slug){

    localStorage.setItem('slug', slug)

    this.router.navigate(['/blog-detail'],{fragment : 'blog'})


  }

}

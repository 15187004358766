import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enterprise-security',
  templateUrl: './enterprise-security.component.html',
  styleUrls: ['./enterprise-security.component.css']
})
export class EnterpriseSecurityComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

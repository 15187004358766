import { Component, OnInit } from '@angular/core';
import {HomeService} from '../services/home.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router'; 
declare var $:any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  providers: [HomeService],
})
export class FooterComponent implements OnInit {

  public newssubcribe: NewsSubcribe;

  constructor(private _homeservice:HomeService, private http:HttpClient, private router:Router) {

    this.newssubcribe = new NewsSubcribe();
   }

  ngOnInit() {
    $(document).ready(function() {
      $('.logo-carousel').slick({
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
        arrows: true,
        dots: false,
        pauseOnHover: false,
        responsive: [{
          breakpoint: 768,
          settings: {
            slidesToShow: 4
          }
        }, {
          breakpoint: 520,
          settings: {
            slidesToShow: 2
          }
        }]
      });
    });
    
  }

  createnewssubscribe(){

    this.http.post(environment.url+'customer/news_subcription/', this.newssubcribe, {}).subscribe(data => {
      
      if (data['error'] == 'True'){

       alert(data['msg']);
      //  this.ClearFields();
       
     }
     
      if (data){
        
        document.getElementById('newsletter').style.display="none"

        alert('Successfully.....')
      
        }
             
    }, error => {

      alert('News subcription with this email already exists..')
      
    }); 


  }


  getfinancialservice()
  {
    this.router.navigate(['/financial-service'], { fragment: 'Financial' });
  }
  getenterprisesecurity()
  {
    this.router.navigate(['/enterprise-security'], { fragment: 'enterprise' });
  }
  
  getbespokesoftware()
  {
    this.router.navigate(['/bespoke-software'], { fragment: 'Bespoke' });
  }
  
  getconsultingandadvisory()
  {
    this.router.navigate(['/consulting-and-advisory'], { fragment: 'consulting' });
  }
  getAnalysisDesign()
  {
    this.router.navigate(['/analysis-and-design'], { fragment: 'Analysis' });
  }
  getDataIntelligence()
  {
    this.router.navigate(['/dataIntelligence'], { fragment: 'Data' });
  }
  getterms()
  {
    this.router.navigate(['/terms'], { fragment: 'terms' });
  }
  getprivacypolicy()
  {
    this.router.navigate(['/privacy-policy'], { fragment: 'privacy' });
  }
  getcookiepolicy()
  {
    this.router.navigate(['/cookie-policy'], { fragment: 'cookie' });
  }



}

export class NewsSubcribe{

  email:string;
}

import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router'; 

@Component({
  selector: 'app-webinar-form',
  templateUrl: './webinar-form.component.html',
  styleUrls: ['./webinar-form.component.css']
})
export class WebinarFormComponent implements OnInit {

  public webinar: Webinar
  public webinarinfo: WebinarInfo

  public countryList: [] = [];

  public selectedCountry: string;

  constructor(private http:HttpClient, private router:Router) {

    this.webinar = new Webinar();
    this.webinarinfo = new WebinarInfo();

   }

  ngOnInit() {

    // this.selectedCountry = "0"

   

    this.webinarinfo.id = localStorage.getItem('id')
    this.webinarinfo.title = localStorage.getItem('title')
    this.webinarinfo.start_date_time = localStorage.getItem('start_date_time')
    this.webinarinfo.duration = localStorage.getItem('duration')
    

    this.getCountry()
  }

 
  
  webinarsubcription(){

    this.webinar.webinar = this.webinarinfo.id
    this.webinar.country = this.selectedCountry
    console.log(this.webinar.country)
    this.http.post(environment.url+'customer/webinar_subcription/', this.webinar, {}).subscribe(data => {
      
      if (data['error'] == true){
       alert(data['message']);
     }
     
      else{
        alert(data['message']);
        this.router.navigate(['/home'])
      
        }
             
    }) 
  }



  getCountry(){

   
    this.http.get(environment.url+'customer/country_list/', {}).subscribe(data => {
      
      if (data){
       
        this.countryList = data['result'].countries;

        console.log(this.countryList)


     }
     
      
             
    }) 
  }

}

export class Webinar{
  email:string;
  first_name:string;
  last_name:string;
  company:string; 
  country:string;
  webinar:string;

}

export class Country{

  id:Number;
  name:string;
  
}

export class WebinarInfo{

  id:string;
  title:string;
  start_date_time:string;
  duration:string;
  

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'; 
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit() {
  }

  getfinancialservice()
  {
    this.router.navigate(['/financial-service'], { fragment: 'Financial' });
  }
  getenterprisesecurity()
  {
    this.router.navigate(['/enterprise-security'], { fragment: 'enterprise' });
  }
  
  getbespokesoftware()
  {
    this.router.navigate(['/bespoke-software'], { fragment: 'Bespoke' });
  }

}

import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router'; 

@Component({
  selector: 'app-webinar',
  templateUrl: './webinar.component.html',
  styleUrls: ['./webinar.component.css']
})
export class WebinarComponent implements OnInit {

  public webinarList: [] = [];
  public  p= 1;
  constructor(private http:HttpClient, private router:Router) { }

  ngOnInit() {

    this.getWebinar()
  }



  getWebinar(){

   
    this.http.get(environment.url+'customer/webinar_list/',{}).subscribe(data => {
      
      if (data){
       
        this.webinarList = data['result'];
     }
     
      
             
    }) 
  }

  getWebinarBy(type){


    console.log(type)

    this.webinarList = [] as [];

    this.http.get(environment.url+'customer/webinar_list/?search='+type,{}).subscribe(data => {
      
      if (data){
       
        this.webinarList = data['result'];
     }
     
      
             
    }) 

  }


  OnSelection(id, title, start_date_time, duration){

   
    
   
    localStorage.setItem('id', id)
    localStorage.setItem('title', title);
    localStorage.setItem('start_date_time', start_date_time);
    localStorage.setItem('duration',duration);
    this.router.navigate(['/webinar-form'])


  }

}

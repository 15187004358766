import { AfterContentInit, AfterViewChecked, AfterViewInit, Component, OnInit } from '@angular/core';

declare var $: any;
@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.css']
})
export class PageHeaderComponent implements OnInit, AfterViewInit {

  constructor() { }
  ngAfterViewInit(): void {
    this.loadEvents()
  }

  loadEvents() {
    $(document).ready(function () {


      if ($('.primary-header').length) {
        $('.header .primary-header .my-btn').on("click", function () {
          $(this).toggleClass('menu-open');
          $('.header .header-menu-wrap').slideToggle(300);
        });

        $('.sticky-header .primary-header .my-btn').on("click", function () {
          $(this).toggleClass('menu-open');
          $('.sticky-header .header-menu-wrap').slideToggle(300);
        });
      }
    });

  }

  ngOnInit() {
    //this.loadEvents()  
  }

}

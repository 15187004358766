import { Injectable } from '@angular/core';
import {SiteUrlConstant  } from '../common/url.constant';
import { RequestMethodRepository } from '../common/requestmethods.repository';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private _requestMethodRepository: RequestMethodRepository) { }


  create_news_subscribe(data) {
    return this._requestMethodRepository.PostWithoutToken(SiteUrlConstant.createnewssubscribe, data);

}





}

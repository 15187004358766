import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router'; 

@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.css']
})
export class JobDetailComponent implements OnInit {
 
public jobid :string
public ID :string
public filename :File
public careermaster: career[] = [];
public JobApply: jobApply
public Model: any
public file :string
public rows: Array<{jobTitle : string, area: string , position:string,jobDescription:string}> = [];
  constructor(private http:HttpClient, private router:Router) {this.JobApply=new jobApply(); }

  ngOnInit() {
this.jobid=localStorage.getItem("jobid")
console.log(this.jobid)
    if(this.jobid!="")
    {
      this.http.get(environment.url+'customer/jobs_list/').subscribe(data => {
      
        if (data){
          console.log(data)
          this.careermaster=data['result']
          console.log(this.careermaster)
         for(let i=0;i<this.careermaster.length;i++)
         {
           if(this.careermaster[i].jobTitle==this.jobid)
           {
             this.ID=this.careermaster[i].id
            this.rows.push( {jobTitle:this.careermaster[i].jobTitle , area:this.careermaster[i].area , position: this.careermaster[i].position,jobDescription:this.careermaster[i].jobDescription} );
           }
         console.log(this.rows)
         }
    }
  }
      )
}
  }
   onFileChange(event) {

  

    if (event.target.files.length > 0) {

      const file =   <File>event.target.files[0];
    
      console.log(file['name'])
      this.filename=file
// fileSource: file
//       // this.myForm.patchValue({

        

//       // });

    }

  }

  jobApply()
  {
      const formData = new FormData();

    formData.append('file', this.filename);

     formData.append('jobTitle',  this.ID);  
    this.http.post(environment.url+'customer/job_apply/',formData,  {}).subscribe(data => {
      
      if (data['error'] == true){
       alert(data['message']);
     }
     
      else{
        alert(data['message']);
        this.router.navigate(['/careers'])
      
        }
             
    }) 
  }
  }
  

export class career
{
  jobTitle : string
  area :string
  position :string
  jobDescription :string
  id :string

}

export class jobApply
{
  jobTitle :number
  file :string
}


import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './home/home.component'
import {AboutComponent} from './about/about.component'
import {AnalysisDesignComponent} from './analysis-design/analysis-design.component'
import {BespokeSoftwareComponent} from './bespoke-software/bespoke-software.component'
import {BlogComponent} from './blog/blog.component'
import {BlogDetailComponent} from './blog-detail/blog-detail.component'
import {CQreenComponent} from './c-qreen/c-qreen.component'
import {CQuanComponent} from './c-quan/c-quan.component'
import {CQurityComponent} from './c-qurity/c-qurity.component'
import {CareerComponent} from './career/career.component'
import {ConsultingAdvisoryComponent} from './consulting-advisory/consulting-advisory.component'
import {ContactComponent} from './contact/contact.component'
import{DataIntelligenceComponent} from './data-intelligence/data-intelligence.component'
import {EnterpriseSecurityComponent} from './enterprise-security/enterprise-security.component'
import {FinancialServicesComponent} from './financial-services/financial-services.component'
import {TestimonialsComponent} from './testimonials/testimonials.component'
import {TutorialComponent} from './tutorial/tutorial.component'
import {WebinarComponent} from './webinar/webinar.component'
import {WebinarFormComponent} from './webinar-form/webinar-form.component'

import {TermsComponent} from './terms/terms.component'
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component'
import {CookiePolicyComponent} from './cookie-policy/cookie-policy.component'
import {DashboardComponent} from './dashboard/dashboard.component'

import {ProductsComponent} from './products/products.component'
import {ServicesComponent} from './services/services.component'
import {RequestDemoComponent} from './request-demo/request-demo.component'
import {LeaveMessageComponent} from './leave-message/leave-message.component'
import {DashboardSidebarComponent} from './dashboard-sidebar/dashboard-sidebar.component'
import {DashboardTopbarComponent} from './dashboard-topbar/dashboard-topbar.component'
import {LoginComponent} from './login/login.component'
import {RequestingDemoComponent} from './requesting-demo/requesting-demo.component'
import {JobDetailComponent} from './job-detail/job-detail.component'

const routes: Routes = [
  { path: 'llllllllll', redirectTo: '/home', pathMatch: 'full' },
  { path: '', component:HomeComponent},
  { path: 'home', component:HomeComponent},
  { path: 'login', component:LoginComponent},
  { path: 'about', component:AboutComponent},
  { path: 'analysis-and-design', component:AnalysisDesignComponent},
  { path: 'bespoke-software', component:BespokeSoftwareComponent},
  { path: 'blog', component:BlogComponent},
  { path: 'cQuan', component:CQuanComponent},
  { path: 'cQreen', component:CQreenComponent},
  { path: 'cQurity', component:CQurityComponent},
  { path: 'careers', component:CareerComponent},
  { path: 'consulting-and-advisory', component:ConsultingAdvisoryComponent},
  { path: 'contact', component:ContactComponent},
  { path: 'dataIntelligence', component:DataIntelligenceComponent},
  { path: 'enterprise-security', component:EnterpriseSecurityComponent},
  { path: 'financial-service', component:FinancialServicesComponent},
  { path: 'testimonials', component:TestimonialsComponent},
  { path: 'blog-detail', component:BlogDetailComponent},
  { path: 'tutorial', component:TutorialComponent},
  { path: 'webinar', component:WebinarComponent},
  {path: 'webinar-form', component:WebinarFormComponent},
  {path: 'dashboard', component:DashboardComponent},

  {path: 'services', component:ServicesComponent},
  {path: 'products', component:ProductsComponent},
  {path: 'request-demo', component:RequestDemoComponent},
  {path: 'leave-message', component:LeaveMessageComponent},
  {path: 'dashboard-sidebar', component:DashboardSidebarComponent},
  {path: 'dashboard-topbar', component:DashboardTopbarComponent},

  { path: 'terms', component:TermsComponent},
  { path: 'privacy-policy', component:PrivacyPolicyComponent},
  { path: 'cookie-policy', component:CookiePolicyComponent},
  { path: 'requesting-demo', component:RequestingDemoComponent},
  { path: 'job-detail', component:JobDetailComponent},
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true, anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

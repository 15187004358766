import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-consulting-advisory',
  templateUrl: './consulting-advisory.component.html',
  styleUrls: ['./consulting-advisory.component.css']
})
export class ConsultingAdvisoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

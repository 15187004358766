import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bespoke-software',
  templateUrl: './bespoke-software.component.html',
  styleUrls: ['./bespoke-software.component.css']
})
export class BespokeSoftwareComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'; 
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit() {
  }


  getcon()
  {
    this.router.navigate(['/services'], { fragment: 'con' });
  }
  getfin()
  {
    this.router.navigate(['/services'], { fragment: 'fin' });
  }
  getent()
  {
    this.router.navigate(['/services'], { fragment: 'ent' });
  }
  getdata()
  {
    this.router.navigate(['/services'], { fragment: 'data' });
  }
  getana()
  {
    this.router.navigate(['/services'], { fragment: 'ana' });
  }
  getint()
  {
    this.router.navigate(['/services'], { fragment: 'int' });
  }


}
